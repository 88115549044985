import React from 'react';

const Informational = React.lazy(() => import('../views/dashboard/Informational'));
// const Analytical = React.lazy(() => import('../views/dashboard/Analytical'));
// const Strategic = React.lazy(() => import('../views/dashboard/Strategic'));

const Admissions = React.lazy(() => import('../views/enrolment/Admissions'));
const Student = React.lazy(() => import('../views/enrolment/Students'));
const Employees = React.lazy(() => import('../views/enrolment/Employees'));
const Subject = React.lazy(() => import('../views/enrolment/Subjects'));
const Attendance = React.lazy(() => import('../views/enrolment/Attendance'));
const Transition = React.lazy(() => import('../views/enrolment/Transition'));
const EnrolmentReports = React.lazy(() => import('../views/enrolment/Reports'));
const EnrolmentSearch = React.lazy(() => import('../views/enrolment/Search'));

// const Marks = React.lazy(() => import('../views/academic_trrs/Marks'));
// const Remarks = React.lazy(() => import('../views/academic_trrs/Remarks'));
// const Activity = React.lazy(() => import('../views/academic_trrs/Activity'));
// const SummarySheet = React.lazy(() => import('../views/academic_trrs/SummarySheet'));
// const AcademicReport = React.lazy(() => import('../views/academic_trrs/AcademicReport'));

const Marks = React.lazy(() => import('../views/academic_shield/Marks'));
const Projects = React.lazy(() => import('../views/academic_shield/Projects'));
const Remarks = React.lazy(() => import('../views/academic_shield/Remarks'));
const Activity = React.lazy(() => import('../views/academic_shield/Activity'));
const SummarySheet = React.lazy(() => import('../views/academic_shield/SummarySheet'));
const AcademicReport = React.lazy(() => import('../views/academic_shield/AcademicReport'));

// const ProofRead = React.lazy(() => import('../views/academic_trrs/ProofRead'));
// const ReportCard = React.lazy(() => import('../views/academic_trrs/ReportCard'));
// const Transcript = React.lazy(() => import('../views/academic_trrs/Transcript'));

const ProofRead = React.lazy(() => import('../views/academic_shield/ProofRead'));
const ReportCard = React.lazy(() => import('../views/academic_shield/ReportCard'));
const Transcript = React.lazy(() => import('../views/academic_shield/Transcript'));

// const SpecialReport = React.lazy(() => import('../views/report/Special'));
// const ReportBuilder = React.lazy(() => import('../views/report/Builder'));
// const GeneralReport = React.lazy(() => import('../views/report/General'));

const GeneralReport = React.lazy(() => import('../views/report/General'));

const Profile = React.lazy(() => import('../views/preference/Profile'));
const Settings = React.lazy(() => import('../views/preference/Setting'));
const Tools = React.lazy(() => import('../views/preference/Tool'));

const MyProfile = React.lazy(() => import('../views/auth/MyProfile'));

const routes = [
    { path: "/dashboard/informational", name: "Informational Dashboard", component: Informational },
    // { path: "/dashboard/cordinator", name: "Cordinators Dashboard", component: Analytical },
    // { path: "/dashboard/strategic", name: "Strategic Dashboard", component: Strategic },

    { path: "/enrolment/admission", name: "Student Admission", component: Admissions },
    { path: "/enrolment/student", name: "Student Enrolment", component: Student },
    { path: "/enrolment/employee", name: "Employees", component: Employees },
    { path: "/enrolment/course", name: "Courses", component: Subject },
    { path: "/enrolment/attendance", name: "Attendance", component: Attendance },
    { path: "/enrolment/transition", name: "Transition", component: Transition },
    { path: "/enrolment/report", name: "Enrolment Report", component: EnrolmentReports },
    { path: "/enrolment/search", name: "Enrolment Search", component: EnrolmentSearch },

    { path: "/academic/marks", name: "Student Marks", component: Marks },
    { path: "/academic/project", name: "Term Projects", component: Projects },
    { path: "/academic/remarks", name: "Remarks/Overview", component: Remarks },
    { path: "/academic/activity", name: "Activities", component: Activity },
    { path: "/academic/proofread", name: "Proof Read", component: ProofRead },
    { path: "/academic/reportcard", name: "Report Card", component: ReportCard },
    { path: "/academic/transcript", name: "Transcript", component: Transcript },
    { path: "/academic/summarysheet", name: "Summary Sheet", component: SummarySheet },
    { path: "/academic/report", name: "Academic Report", component: AcademicReport },

    // { path: "/report/special", name: "Special Report", component: SpecialReport },
    // { path: "/report/builder", name: "Report Builder", component: ReportBuilder },
    { path: "/report/general", name: "Report Builder", component: GeneralReport },

    { path: "/preference/profile", name: "User Profiles", component: Profile },
    { path: "/preference/settings", name: "General Settings", component: Settings },
    { path: "/preference/tools", name: "Tools", component: Tools },

    { path: "/preference/myprofile", name: "My Profile", component: MyProfile }
]

export default routes;