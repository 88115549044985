import React from 'react';
import DefaultLayout from './components/DefaultLayout';
import './App.css';
import './components/portal.js';
import Login from './views/auth/Login';
import { IsAuthenticated } from './components/MojaveLib';

const App = () => IsAuthenticated() ? <DefaultLayout /> : <Login />

export default App;
