import React, { Fragment, Suspense }from 'react';
import mojsymbol from './img/mojave_symb_white.png';
import avatar from './img/noimage.png';
import routes from './routes';
import { createBrowserHistory } from 'history';
import { HashRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { GetJCookie, GetCurl, GetUser, Loader, Logout, Menu, GetCookie, GetFeatures } from './MojaveLib';
import moment from 'moment';

const features =  JSON.parse(GetFeatures());

// try{features = JSON.parse(GetFeatures());}catch(error){console.log(error.message)}

const PrimaryMenu = () => {
    let curl = GetCurl(useLocation(),1);
    let loc = useLocation().hash;

    return(<nav id="primary_nav">
        <ul>
            {Menu !== null && Menu.map(menu => features !== null && features[`${menu.code}`] !== undefined && (
                <li className={`${menu.icon} ${curl===menu.link && 'active'}`}><a href={loc}>{menu.name}</a></li>
            ))}
        </ul>
    </nav>)
};

const Profile = (props) => {
    let loc = useLocation().hash;
    let profile = GetUser();

    return <dl class="user_info">
        <dt>
            <a href={loc}><img src={avatar} alt="avatar" className="bg-white" style={{width:'79px',height:'79px'}} /></a>
        </dt>
        <dd> 
            <a class="welcome_user" href={`#/preference/myprofile`}>Welcome, <strong>{profile.firstname}</strong></a> 
            <span class="log_data">Last login: {moment(profile.lastlogin).format('DD MMM, YYYY @ HH:mm')}</span> 
            <a class="logout" href={loc} onClick={Logout}>Logout</a> 
            <a class="user_messages" href={loc}><span>0</span></a> 
        </dd>
    </dl>
};

const MobileMenu = (props) => {
    let loc = useLocation().hash;
    let curl = GetCurl(useLocation(),2);

    return <Fragment>
        <a class="res_icon" href={loc}>&nbsp;</a>
        <ul id="responsive_nav">
            <li> <a href={loc} >Dashboard</a> 
                <li className={curl === 'informational' && 'active'}><a href="#/dashboard/informational"><span class="iconsweet">&#128203;</span>Informational</a> </li>
                <li className={curl === 'analytical' && 'active'}><a href="#/dashboard/analytical"><span class="iconsweet">&#128466;</span>Analytical</a></li>
                <li className={curl === 'strategic' && 'active'}><a href="#/dashboard/strategic"><span class="iconsweet">&#128214;</span>Strategic</a></li>
            </li>
            <li> <a href={loc}>Enrolment</a>
            <ul>
                <li className={curl === 'student' && 'active'}><a href="#/enrolment/student"><span class="iconsweet">&#128451;</span>Students Records</a></li>
                {/* <li className={curl === 'faculty' && 'active'}><a href="#/enrolment/faculty"><span class="iconsweet">&#127960;</span>Classroom</a></li> */}
                {/* <li className={curl === 'course' && 'active'}><a href="#/enrolment/course"><span class="iconsweet">&#128450;</span>Courses</a></li> */}
                {/* <li className={curl === 'attendance' && 'active'}><a href="#/enrolment/attendance"><span class="iconsweet">&#127960;</span>Attendance</a></li> */}
                {/* <li className={curl === 'facilitator' && 'active'}><a href="#/enrolment/facilitator"><span class="iconsweet">&#128372;</span>Facilitators</a></li> */}
                <li className={curl === 'report' && 'active'}><a href="#/enrolment/report"><span class="iconsweet">&#128218;</span>Reports</a></li>
            </ul>
            </li>
            <li> <a href={loc}>Academic</a>
            <ul>
                <li><a href="#/academic/marks"><span class="iconsweet">v</span>Term Marks</a></li>
                <li><a href="#/academic/activity"><span class="iconsweet">&amp;</span>Term Overview</a></li>
                <li><a href="#/academic/remarks"><span class="iconsweet">&amp;</span>Term Comments</a></li>
                {/* <li><a href="#/academic/proofread"><span class="iconsweet">O</span>Proof Reading</a></li>
                <li><a href="#/academic/reportcard"><span class="iconsweet">O</span>Report Card</a></li>
                <li><a href="#/academic/transcript"><span class="iconsweet">O</span>Transcript</a></li> */}
                <li><a href="#/academic/report"><span class="iconsweet">O</span>Reports</a></li>
            </ul>
            </li>
            <li> <a href={loc}>General Reports</a>
            <ul>
                <li className={curl === 'special' && 'active'}><a href={`#report/special`}><span class="iconsweet">&#128478;</span>Special Report</a></li>
                <li className={curl === 'builder' && 'active'}><a href={`#report/builder`}><span class="iconsweet">&#128223;</span>Report Builder</a></li>
            </ul>
            </li>
            <li> <a href={loc}>preference</a>
            <ul>
                <li className={curl === 'profile' && 'active'}><a href={`#preference/profile`}><span class="iconsweet">&#128106;</span>Profile</a></li>
                <li className={curl === 'settings' && 'active'}><a href={`#preference/settings`}><span class="iconsweet">&#127899;</span>Settings</a></li>
                <li className={curl === 'tools' && 'active'}><a href={`#preference/tools`}><span class="iconsweet">&#128736;</span>Tools</a></li>
            </ul>
            </li>
        </ul>
    </Fragment>
};

const Subnav = (props) => {
    let murl = GetCurl(useLocation(),1);
    let curl = GetCurl(useLocation(),2);
    
    return (<ol id="subnav">
        {Menu.map(menu => features !== null && features[`${menu.code}`] !== undefined && (
            <li className={murl !== menu.link && 'hide'}>
                <h2>{menu.name}</h2>
                <ul>
                    {menu.submenu.map(smenu => features[`${menu.code}`].includes(smenu.code) && (
                        <li className={curl === smenu.link && 'active'}>{smenu.name} </li>
                    ))}
                </ul>
            </li>
        ))}
    </ol>)
};

const history = createBrowserHistory({
    basename: '/schom_shield/'
}) 

const DefaultLayout = (props) => {
    let murl = GetCurl(useLocation(),1);
    let curl = GetCurl(useLocation(),2);

    return(
        <Fragment>
            <header> 
                <div className="row">
                    <div className="col-4">
                        <a href={`#${murl}/${curl}`} className='logo-text'><img src={mojsymbol} alt="" className="logo-icon" /> School Manager</a>
                    </div>
                    <div className="col-8 company-name">
                        {GetCookie('company') !== null && GetJCookie('company')[0].name}
                    </div>
                </div>
            </header>
            <div id="dreamworks_container"> 
                <PrimaryMenu />
            
                <section id="main_content"> 
                <nav id="secondary_nav"> 
                    <Profile />
                    
                    <MobileMenu />

                    <Subnav />
                </nav>
                
                <Suspense fallback={Loader()}>
                    <HashRouter>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component ? (
                                <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                    <route.component {...props} />
                                    )} />
                                ) : (null);
                            })}
                            {<Redirect from="/" to="/dashboard/informational" /> }
                        </Switch>
                    </HashRouter>
                </Suspense>
                </section>
            </div>
        </Fragment>
    )
}

export default DefaultLayout;