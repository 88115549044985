import $ from 'jquery';

$(function(e) {

    $("#secondary_nav ol#subnav li ul li").on('click tap', function(e){
        e.stopPropagation();
        $("#secondary_nav ol#subnav li ul li").removeClass('active');
        $(this).addClass('active');
    });

    $("nav#primary_nav ul li").on('click tap', function(e){
        e.stopPropagation();

        if($(this).hasClass('active')){
            
        }else{
            $("nav#primary_nav ul li").removeClass('active');
            $("ol#subnav>li").addClass('hide');

            $(this).addClass('active');
            $(`#subnav>li:nth-child(${$(this).index()+1})`).removeClass('hide');
        }
    });

    // $('#settingBtns button').on('click tap', function(){
    //     alert('it was here');
    //     // $('$settingBtns button').removeClass('active');

    //     // alert($(e.target).text());
    //     // $(e.target).addClass('active');
    // });

});