import React, { useState } from 'react';
// import logo from '../../components/img/mojave_symb_white.png';
import logo from '../../components/img/crest_shield.png'
import { DoLogin } from '../../components/MojaveLib';

function Login(){

    const [login, setLogin] = useState({email:'',password:''});

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setLogin(prevState => ({ ...prevState, [name]: value }));
    };


    const submitForm = (e) => {
        e.preventDefault();

        let formData = {...login};
            formData.email = formData.email.trim();
        
        DoLogin(formData);
    };

    return(
        <div id="dreamworks_container" >
            <div id="login" className="vcenter">
                <a className='logo-text' href="#/" style={{fontSize:'x-large',display:'block',marginBottom:'10px'}}>
                    <img src={logo} alt="" className="logo-icon" style={{width:'72px',height:'80px',marginTop:'8px'}} /> School Manager
                </a>
                <form id="frmLogin" method="POST" onSubmit={submitForm}>
                    <div class="input_box"> <span class="iconsweet">a</span>
                        <input placeholder="Email" name="email" type="text" id="email" style={{width:'85%',height:'35px'}} onChange={handleChange} />
                    </div>
                    <div class="input_box"> <span class="iconsweet">y</span>
                        <input placeholder="Password" name="password" type="password" id="password" style={{width:'50%',height:'35px'}} onChange={handleChange} />
                    </div>
                    <div> <a class="forgot_password" style={{color:'white'}} href="#/auth/forgotPassword">Have you forgotten your password?</a> <input name="" type="submit" value="Login" /></div>
                </form>
            </div>
        </div>
    )
}

export default Login;